import React from "react";
import { graphql } from "gatsby"

import Metadata from '../components/Metadata';
import PageLayout from "../layouts/Page";
import TextPancake from "../components/pancakes/Text";

export const query = graphql`
  query BasicPageQuery($id: String!) {
    basicPage: sanityBasicPage(_id: { eq: $id }) {
      _id
      pageTitle
      slug {
        current
      }
      _rawText(resolveReferences:{maxDepth:1000})
      seo {
        metaDescription
        metaTitle
        ogImage {
          asset {
            url
          }
        }
      }
    }
  }
`;

const BasicPage = props => {

  const {basicPage} = props.data

  return (
    <PageLayout>
      <Metadata 
        title={basicPage.seo?.metaTitle || basicPage.pageTitle} 
        description={basicPage.seo?.metaDescription} 
        image={basicPage.seo?.ogImage?.asset.url || null}
      />
      <TextPancake blocks={basicPage._rawText}></TextPancake>
    </PageLayout>
  );
}

export default BasicPage;
