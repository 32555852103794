import React from 'react';
import { graphql } from "gatsby"
import classnames from 'classnames';
import SanityPortableText from "../SanityPortableText";

import PancakeIntro from "../PancakeIntro";
import Pancake from "./Pancake";
import Container from "../Container";

import "./Text.scss";

const Text = props => {

  const { title, text, blocks, theme, className } = props;

  return (
    <Pancake className={classnames("PancakeText", className)} theme={theme}>
      <Container size="Small">
        {(title || text) && <PancakeIntro title={title} text={text} />}
        <SanityPortableText blocks={blocks} />
      </Container>
    </Pancake>
  );
}

export const query = graphql`
  fragment SanityPancakeText on SanityPancakeText {
    _key
    _type
    pancakeIntro {
      pancakeText
      pancakeTitle
    }
    _rawPancakeText(resolveReferences:{maxDepth:1000})
    theme
  }
`

export default Text;